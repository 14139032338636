/* stylelint-disable declaration-block-no-shorthand-property-overrides */
/* stylelint-disable scss/at-rule-no-unknown */
/* stylelint-disable selector-not-notation */
/* stylelint-disable value-keyword-case */
:root,
.light {
  --color-body: 255 255 255;
  --color-text: 093 108 130;
  --color-lead: 015 023 042;
  --color-subs: 051 065 085;
  --color-fore: 248 250 252;
  --color-main: 124 058 237;
  --color-seco: 004 135 094;
  --color-tone: 079 070 229;
  --color-mark: 249 115 022;
  --color-mute: 248 250 252;
  --color-soft: 232 237 242;
  --color-line: 223 225 229;
  --color-edge: 191 191 191;
  --color-back: 038 051 068;
  --color-fire: 185 028 028;
  --color-warn: 245 158 011;
  --color-wins: 004 120 087;
  --color-info: 013 113 205;
}
:root ::backdrop,
.light ::backdrop {
  --color-back: 038 051 068;
}

.dark {
  --color-body: 015 021 030;
  --color-text: 125 140 162;
  --color-lead: 255 255 255;
  --color-subs: 203 213 225;
  --color-fore: 015 021 030;
  --color-main: 167 139 250;
  --color-seco: 052 211 153;
  --color-tone: 129 140 248;
  --color-mark: 251 146 060;
  --color-mute: 020 028 039;
  --color-soft: 027 037 051;
  --color-line: 038 051 068;
  --color-edge: 060 073 091;
  --color-back: 038 051 068;
  --color-fire: 248 113 113;
  --color-warn: 255 219 089;
  --color-wins: 104 204 069;
  --color-info: 034 211 238;
}
.dark ::backdrop {
  --color-back: 038 051 068;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("./../assets/fonts/inter.woff2") format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 700;
    src: url("./../assets/fonts/CabinetGrotesk-Bold.woff2") format("woff2");
  }
  html {
    @apply grid min-h-full;
  }
  body {
    @apply bg-body text-sm text-text font-inter;
  }
  body > #app,
  body > #__next,
  body > div {
    @apply flex flex-col min-h-full;
  }
  h1,
  h2,
  h3,
  div,
  li,
  p {
    overflow-wrap: break-word;
  }
  h1,
  h2,
  h3 {
    @apply font-semibold;
  }
  * {
    @apply min-w-0;
  }
  [hidden] {
    display: none;
  }
  label {
    @apply text-sm font-medium select-none;
  }
  [class*=ic-] {
    display: inline-flex;
    gap: 0.25rem;
  }
  [class*=ic-]:not(.menu-item):not(.ic-before):not(.ic-after):not(svg),
  [class*=ic-].menu-item > a::before,
  .ic-before::before,
  .ic-after::after,
  .menu-item-has-children > a::after {
    width: 1em;
    height: 1em;
    line-height: 1;
    flex-shrink: 0;
    max-width: initial;
    background-color: currentColor;
    mask-size: 100% 100%;
    mask: var(--ic) no-repeat;
  }
  .ic-before::before,
  .ic-after::after,
  [class*=ic-].menu-item > a::before,
  .menu-item-has-children > a::after {
    content: "";
  }
  svg[class*=ic-] {
    stroke-width: 2px;
    width: 1em;
    height: 1em;
  }
  svg[class*=ic-] use {
    fill: currentColor;
    stroke: currentColor;
  }
  input,
  textarea,
  select {
    @apply text-base min-h-12 appearance-none block px-4 py-1.5 rounded-lg w-full border placeholder:text-text placeholder:opacity-100 font-normal text-subs bg-mute border-line disabled:bg-line disabled:opacity-80;
  }
  :is(input, textarea, select):focus {
    @apply bg-body outline-none border-text/50 ring-4 ring-text ring-opacity-10;
  }
  textarea {
    @apply min-h-32 h-32 max-h-96;
  }
  select {
    --ic: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-image: var(--ic);
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    @apply pe-9;
  }
  [type=checkbox],
  [type=radio] {
    @apply appearance-none p-0 w-5 h-5 self-start rounded min-h-0 cursor-pointer bg-mute border border-line hover:bg-soft dark:border-edge;
  }
  [type=radio] {
    @apply rounded-full;
  }
  [type=checkbox]:checked,
  [type=checkbox]:indeterminate,
  [type=radio]:checked,
  [type=radio]:indeterminate {
    @apply bg-current text-main border-main ring-main/20;
  }
  [type=radio]:checked,
  [type=checkbox]:checked {
    --ic: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-image: var(--ic);
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  [class=dark] [type=radio]:checked,
  [class=dark] [type=checkbox]:checked {
    --ic: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
  [type=radio]:indeterminate,
  [type=checkbox]:indeterminate {
    --ic: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 12a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1z' fill='white'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-image: var(--ic);
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  [class=dark] [type=radio]:indeterminate,
  [class=dark] [type=checkbox]:indeterminate {
    --ic: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 12a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-image: var(--ic);
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}
@layer components {
  .cont {
    @apply w-full mx-auto max-[375px]:px-4 max-w-[640px] md:max-w-3xl lg:max-w-[1008px] px-6;
  }
  .container {
    @apply mx-auto max-[375px]:px-4 max-lg:max-w-none px-6;
  }
  .btn,
  .menu-item > a {
    @apply min-w-9 min-h-9 py-2 px-3 border border-transparent rounded inline-flex shrink-0 justify-center items-center leading-tight gap-2 select-none cursor-pointer font-medium hover:transition hover:duration-200 active:scale-95 disabled:opacity-50 disabled:pointer-events-none focus:outline-none hover:text-lead;
  }
  .btn-item {
    @apply text-subs hover:bg-mute hover:text-lead [&.on]:bg-mute [&.on]:text-lead;
  }
  .btn-nav {
    @apply px-4 py-3.5 rounded-lg w-full justify-start text-start gap-1.5 [&_.ic]:text-lg [&_.ic]:text-text text-subs hover:bg-mute hover:text-lead hover:shadow [&.on]:bg-main/10 [&.on]:text-main [&.on_.ic]:text-main [&.on]:hover:shadow-none;
  }
  .btn-main {
    @apply text-fore bg-main hover:bg-main/90 hover:text-fore;
  }
  .btn-main-light {
    @apply text-main bg-main/10 hover:bg-main hover:text-fore;
  }
  .btn-main-ghost {
    @apply text-main hover:bg-main/10 hover:text-main;
  }
  .btn-wins-light-outline {
    @apply text-wins bg-wins/10 border border-wins/20 hover:bg-wins hover:text-fore;
  }
  .btn-seco {
    @apply bg-soft hover:bg-line hover:text-lead;
  }
  .btn-fire {
    @apply bg-fire text-fore hover:bg-fire/90 hover:text-fore;
  }
  .btn-info {
    @apply bg-info text-fore hover:bg-info/90 hover:text-fore;
  }
  .btn-seco-ghost {
    @apply text-subs hover:bg-soft hover:text-lead disabled:bg-soft;
  }
  .btn-seco-outline {
    @apply text-subs bg-soft border-line hover:bg-line hover:border-edge hover:text-lead;
  }
  .btn-sm {
    @apply min-w-8 min-h-8 py-1;
  }
  .btn-md {
    @apply min-w-10 min-h-10;
  }
  .btn-lg {
    @apply min-w-12 min-h-12;
  }
  .entry > *,
  .entry li > *:first-child {
    @apply mt-6;
  }
  .entry > *,
  .entry p {
    @apply mb-6;
  }
  .entry li {
    @apply mt-1.5;
  }
  .entry > *:first-child {
    @apply mt-0;
  }
  .entry > *:last-child {
    @apply mb-0;
  }
  .entry :is(h1, h2, h3, h4, h5, h6) + * {
    @apply mb-2 mt-0;
  }
  .entry :is(h1, h2, h3, h4, h5, h6) {
    @apply text-lead font-bold mb-2;
  }
  .entry h1 {
    @apply text-3xl;
  }
  .entry :is(h2, h3) {
    @apply text-2xl;
  }
  .entry :is(h4, h5, h6) {
    @apply text-base;
  }
  .entry a {
    @apply text-main font-bold hover:underline;
  }
  .entry pre {
    @apply bg-mute p-4 text-subs;
  }
  .entry strong {
    @apply font-bold;
  }
  .entry blockquote {
    @apply relative font-bold z-10 text-lead ps-12 text-xl sm:text-2xl before:text-tone before:start-0 before:top-0 before:absolute before:text-3xl;
  }
  .entry blockquote::before {
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath fill='%23EF008B' d='M24.667 26.667a6 6 0 000-12c-.028 0-.054.008-.08.008a31.716 31.716 0 013.224-8.656 1.333 1.333 0 00-2.087-1.628c-.288.288-7.057 7.154-7.057 15.609.008.075.023.15.045.223-.012.149-.045.292-.045.444a6.007 6.007 0 006 6zM1.333 20.667a6 6 0 106-6c-.028 0-.053.008-.08.008a31.713 31.713 0 013.224-8.656A1.333 1.333 0 008.391 4.39C8.103 4.679 1.333 11.545 1.333 20c.009.075.024.15.046.223-.012.149-.046.292-.046.444z'/%3E%3C/svg%3E");
    width: 1em;
    height: 1em;
    background-color: currentColor;
  }
  .entry cite {
    @apply text-sm;
  }
  .entry figcaption {
    @apply mt-2 italic text-xs;
  }
  .entry :is(ul, ol):not([class]) {
    @apply ps-8 ms-3 my-4;
  }
  .entry :is(ul):not([class]) {
    @apply list-disc;
  }
  .entry :is(ol):not([class]) {
    @apply list-decimal;
  }
  .entry ul li {
    @apply marker:text-main marker:text-xl;
  }
  .entry table {
    @apply w-full table-auto;
  }
  .entry :is(th, td) {
    @apply p-2;
  }
  .entry tr {
    @apply border-b border-line last:border-b-0;
  }
  .entry code {
    @apply bg-main/10 text-main px-1.5 py-0.5 rounded-md;
  }
  /* tooltip */
  [class*=tooltip-] {
    @apply relative;
  }
  [class*=tooltip-]::before {
    content: attr(aria-label);
    animation-delay: 0.5s !important;
    @apply absolute max-w-xs rounded px-1.5 py-0.5 bg-lead/90 text-fore font-medium text-xs w-max pointer-events-none z-40 hidden animate-fadeIn;
  }
  [class*=tooltip-]:hover::before {
    @apply block;
  }
  .tooltip-top::before {
    @apply bottom-full start-1/2 -translate-x-1/2 mb-1;
  }
  .tooltip-top-end::before {
    @apply bottom-full end-0 mb-1;
  }
  .tooltip-top-start::before {
    @apply bottom-full start-0 mb-1;
  }
  .tooltip-bottom::before {
    @apply top-full start-1/2 -translate-x-1/2 mt-1;
  }
  .tooltip-bottom-end::before {
    @apply top-full end-0 mt-1;
  }
  .tooltip-bottom-start::before {
    @apply top-full start-0 mt-1;
  }
  .tooltip-end::before {
    @apply top-1/2 -translate-y-1/2 start-full ms-1;
  }
  .tooltip-start::before {
    @apply top-1/2 -translate-y-1/2 end-full me-1;
  }
  .top {
    counter-increment: top;
    @apply before:shrink-0 before:w-5 before:h-5 before:text-sm before:font-semibold before:text-center;
  }
  .top::before {
    content: counter(top);
  }
  .ic-sun {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 2v2m0 16v2M4 12H2m4.314-5.686L4.9 4.9m12.786 1.414L19.1 4.9M6.314 17.69L4.9 19.104m12.786-1.414l1.414 1.414M22 12h-2m-3 0a5 5 0 11-10 0 5 5 0 0110 0z'/%3E%3C/svg%3E");
  }
  .ic-moon-star {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M18 2l.618 1.236c.265.53.398.796.575 1.026.158.204.34.387.545.545.23.177.495.31 1.026.575L22 6l-1.236.618c-.53.265-.796.398-1.026.575-.204.158-.387.34-.545.545-.177.23-.31.495-.575 1.026L18 10l-.618-1.236c-.265-.53-.398-.796-.575-1.026a2.996 2.996 0 00-.545-.545c-.23-.177-.495-.31-1.026-.575L14 6l1.236-.618c.53-.265.796-.398 1.026-.575.204-.158.387-.34.545-.545.177-.23.31-.495.575-1.026L18 2zM21 13.39A7.617 7.617 0 1110.611 3C5.78 3.458 2 7.527 2 12.479A9.521 9.521 0 0011.521 22c4.952 0 9.02-3.78 9.479-8.61z'/%3E%3C/svg%3E");
  }
  .ic-menu-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12h18M3 6h18M3 18h18'/%3E%3C/svg%3E");
  }
  .ic-x-close {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M18 6L6 18M6 6l12 12'/%3E%3C/svg%3E");
  }
  .ic-chevron-right {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 18l6-6-6-6'/%3E%3C/svg%3E");
  }
  .ic-videos {
    --ic: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%23212121' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Ccircle fill='none' stroke='%23212121' stroke-width='2' cx='12' cy='12' r='11'%3E%3C/circle%3E %3Cpolygon fill='none' stroke='%23212121' stroke-width='2' points=' 10,16 10,8 16,12 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
  }
  .ic-images {
    --ic: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%23212121' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpolygon fill='none' stroke='%23212121' stroke-width='2' points='2,22 6,14 10,17 16,9 22,22 '%3E%3C/polygon%3E %3Ccircle fill='none' stroke='%23212121' stroke-width='2' cx='8' cy='5' r='3'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  }
  .ic-arrow-right {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 12h14m0 0l-7-7m7 7l-7 7'/%3E%3C/svg%3E");
  }
  .ic-minus-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 12h8m6 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-plus-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 8v8m-4-4h8m6 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-user-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M20 21c0-1.396 0-2.093-.172-2.661a4 4 0 00-2.667-2.667c-.568-.172-1.265-.172-2.661-.172h-5c-1.396 0-2.093 0-2.661.172a4 4 0 00-2.667 2.667C4 18.907 4 19.604 4 21M16.5 7.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z'/%3E%3C/svg%3E");
  }
  .ic-alert-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 8v4m0 4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-copy-02 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M16 8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C14.48 2 13.92 2 12.8 2H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C2 3.52 2 4.08 2 5.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C3.52 16 4.08 16 5.2 16H8m3.2 6h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 20.48 22 19.92 22 18.8v-7.6c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 8 19.92 8 18.8 8h-7.6c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C8 9.52 8 10.08 8 11.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C9.52 22 10.08 22 11.2 22z'/%3E%3C/svg%3E");
  }
  .ic-folder-plus {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 7l-1.116-2.231c-.32-.642-.481-.963-.72-1.198a2 2 0 00-.748-.462C10.1 3 9.74 3 9.022 3H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C2 4.52 2 5.08 2 6.2V7m0 0h15.2c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C22 9.28 22 10.12 22 11.8v4.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C19.72 21 18.88 21 17.2 21H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C2 18.72 2 17.88 2 16.2V7zm10 10v-6m-3 3h6'/%3E%3C/svg%3E");
  }
  .ic-plus {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 5v14m-7-7h14'/%3E%3C/svg%3E");
  }
  .ic-info-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 16v-4m0-4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-home-02 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 21v-7.4c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C9.76 12 10.04 12 10.6 12h2.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C15 12.76 15 13.04 15 13.6V21M11.018 2.764L4.235 8.039c-.453.353-.68.53-.843.75a2 2 0 00-.318.65C3 9.704 3 9.991 3 10.565V17.8c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C4.52 21 5.08 21 6.2 21h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C21 19.48 21 18.92 21 17.8v-7.235c0-.574 0-.861-.074-1.126a2.002 2.002 0 00-.318-.65c-.163-.22-.39-.397-.843-.75l-6.783-5.275c-.351-.273-.527-.41-.72-.462a1 1 0 00-.523 0c-.194.052-.37.189-.721.462z'/%3E%3C/svg%3E");
  }
  .ic-trash-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M16 6v-.8c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C14.48 2 13.92 2 12.8 2h-1.6c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C8 3.52 8 4.08 8 5.2V6m2 5.5v5m4-5v5M3 6h18m-2 0v11.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C16.72 22 15.88 22 14.2 22H9.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C5 19.72 5 18.88 5 17.2V6'/%3E%3C/svg%3E");
  }
  .ic-folder {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 7l-1.116-2.231c-.32-.642-.481-.963-.72-1.198a2 2 0 00-.748-.462C10.1 3 9.74 3 9.022 3H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C2 4.52 2 5.08 2 6.2V7m0 0h15.2c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C22 9.28 22 10.12 22 11.8v4.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C19.72 21 18.88 21 17.2 21H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C2 18.72 2 17.88 2 16.2V7z'/%3E%3C/svg%3E");
  }
  .ic-bar-chart-circle-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 13v4m8-6v6M12 7v10m10-5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-upload-03 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M16 12l-4-4m0 0l-4 4m4-4v8m10-4c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-zap-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 5.5l-4.638 6.747c-.28.406-.42.609-.41.778a.5.5 0 00.195.373c.135.102.38.102.874.102H12v5l4.638-6.747c.28-.406.42-.609.41-.778a.5.5 0 00-.195-.373c-.135-.102-.38-.102-.874-.102H12v-5z'/%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'/%3E%3C/svg%3E");
  }
  .ic-user-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5.316 19.438A4.001 4.001 0 019 17h6a4.001 4.001 0 013.684 2.438M16 9.5a4 4 0 11-8 0 4 4 0 018 0zm6 2.5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-file-06 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M14 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 00.437.437c.214.11.494.11 1.054.11h4.13M16 13H8m8 4H8m2-8H8m6-7H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C6.28 22 7.12 22 8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C20 19.72 20 18.88 20 17.2V8l-6-6z'/%3E%3C/svg%3E");
  }
  .ic-box {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M20.5 8v8.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.22 21 17.38 21 15.7 21H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C3.5 18.72 3.5 17.88 3.5 16.2V8m.1-5h16.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C22 3.76 22 4.04 22 4.6v1.8c0 .56 0 .84-.109 1.054a1 1 0 01-.437.437C21.24 8 20.96 8 20.4 8H3.6c-.56 0-.84 0-1.054-.109a1 1 0 01-.437-.437C2 7.24 2 6.96 2 6.4V4.6c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C2.76 3 3.04 3 3.6 3zm6 8.5h4.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C16 12.26 16 12.54 16 13.1v.8c0 .56 0 .84-.109 1.054a1 1 0 01-.437.437c-.214.109-.494.109-1.054.109H9.6c-.56 0-.84 0-1.054-.109a1 1 0 01-.437-.437C8 14.74 8 14.46 8 13.9v-.8c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C8.76 11.5 9.04 11.5 9.6 11.5z'/%3E%3C/svg%3E");
  }
  .ic-hard-drive {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2.5 12h19M6 16h4M8.967 4h6.066c1.077 0 1.616 0 2.091.164a3 3 0 011.121.693c.36.352.6.833 1.082 1.796l2.166 4.333c.19.378.284.567.35.765.06.177.102.357.128.541.029.207.029.418.029.841V15.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C19.72 20 18.88 20 17.2 20H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C2 17.72 2 16.88 2 15.2v-2.067c0-.422 0-.634.029-.84.026-.184.068-.365.128-.541.066-.199.16-.388.35-.766l2.166-4.333c.482-.963.723-1.444 1.082-1.796a3 3 0 011.12-.693C7.352 4 7.89 4 8.968 4z'/%3E%3C/svg%3E");
  }
  .ic-lock-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M17 10V8A5 5 0 007 8v2m5 4.5v2M8.8 21h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C20 18.72 20 17.88 20 16.2v-1.4c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C17.72 10 16.88 10 15.2 10H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C4 12.28 4 13.12 4 14.8v1.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C6.28 21 7.12 21 8.8 21z'/%3E%3C/svg%3E");
  }
  .ic-log-out-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M16 17l5-5m0 0l-5-5m5 5H9m0-9H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C5.28 21 6.12 21 7.8 21H9'/%3E%3C/svg%3E");
  }
  .ic-layout-alt-03 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M17.5 17h-11m11-4h-11M3 9h18M7.8 3h8.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C21 5.28 21 6.12 21 7.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C3 18.72 3 17.88 3 16.2V7.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C5.28 3 6.12 3 7.8 3z'/%3E%3C/svg%3E");
  }
  .ic-layout-grid-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12h18m-9-9v18M7.8 3h8.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C21 5.28 21 6.12 21 7.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C3 18.72 3 17.88 3 16.2V7.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C5.28 3 6.12 3 7.8 3z'/%3E%3C/svg%3E");
  }
  .ic-filter-lines {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 12h12M3 6h18M9 18h6'/%3E%3C/svg%3E");
  }
  .ic-help-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3m.08 4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-edit-02 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M18 10l-4-4M2.5 21.5l3.384-.376c.414-.046.62-.069.814-.131a2 2 0 00.485-.234c.17-.111.317-.259.61-.553L21 7a2.828 2.828 0 10-4-4L3.794 16.206c-.294.294-.442.442-.553.611a2 2 0 00-.234.485c-.062.193-.085.4-.131.814L2.5 21.5z'/%3E%3C/svg%3E");
  }
  .ic-eye {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2.42 12.713c-.136-.215-.204-.323-.242-.49a1.173 1.173 0 010-.446c.038-.167.106-.274.242-.49C3.546 9.505 6.895 5 12 5s8.455 4.505 9.58 6.287c.137.215.205.323.243.49.029.125.029.322 0 .446-.038.167-.106.274-.242.49C20.455 14.495 17.105 19 12 19c-5.106 0-8.455-4.505-9.58-6.287z'/%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 15a3 3 0 100-6 3 3 0 000 6z'/%3E%3C/svg%3E");
  }
  .ic-refresh-cw-02 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 14s.121.85 3.636 4.364A9 9 0 0020.776 14M2 14v6m0-6h6m14-4s-.121-.85-3.636-4.364A9 9 0 003.224 10M22 10V4m0 6h-6'/%3E%3C/svg%3E");
  }
  .ic-users-plus {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 21v-6m-3 3h6m-10-3H8c-1.864 0-2.796 0-3.53.305a4 4 0 00-2.166 2.164C2 18.204 2 19.136 2 21M15.5 3.29a4.001 4.001 0 010 7.42M13.5 7a4 4 0 11-8 0 4 4 0 018 0z'/%3E%3C/svg%3E");
  }
  .ic-slash-circle-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4.93 4.93l14.14 14.14M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-layout-left {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 3v18M7.8 3h8.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C21 5.28 21 6.12 21 7.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C3 18.72 3 17.88 3 16.2V7.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C5.28 3 6.12 3 7.8 3z'/%3E%3C/svg%3E");
  }
  .ic-settings-02 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 15a3 3 0 100-6 3 3 0 000 6z'/%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9.29 19.371l.584 1.315a2.213 2.213 0 004.044 0l.585-1.315a2.426 2.426 0 012.47-1.423l1.43.152a2.212 2.212 0 002.022-3.502l-.847-1.164a2.428 2.428 0 01-.46-1.434c0-.513.163-1.014.465-1.429l.847-1.163a2.212 2.212 0 00-2.023-3.502l-1.43.152a2.433 2.433 0 01-1.47-.312 2.425 2.425 0 01-1-1.117l-.588-1.315a2.212 2.212 0 00-4.045 0L9.29 4.63c-.207.468-.558.86-1 1.117-.445.256-.96.365-1.47.312l-1.435-.152a2.212 2.212 0 00-2.022 3.502l.847 1.163a2.43 2.43 0 010 2.858l-.847 1.163a2.21 2.21 0 00.786 3.273c.381.195.81.274 1.236.23l1.43-.153a2.434 2.434 0 012.475 1.43z'/%3E%3C/svg%3E");
  }
  .ic-upload-cloud-02 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 16l4-4m0 0l4 4m-4-4v9m8-4.257A5.5 5.5 0 0016.5 7a.62.62 0 01-.534-.302 7.5 7.5 0 10-11.78 9.096'/%3E%3C/svg%3E");
  }
  .ic-file-04 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M14 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 00.437.437C14.76 8 15.04 8 15.6 8h4.13M20 9.988V17.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C17.72 22 16.88 22 15.2 22H8.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C4 19.72 4 18.88 4 17.2V6.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C6.28 2 7.12 2 8.8 2h3.212c.733 0 1.1 0 1.446.083.306.073.598.195.867.36.303.185.562.444 1.08.963l3.19 3.188c.518.519.777.778.963 1.081a3 3 0 01.36.867c.082.346.082.712.082 1.446z'/%3E%3C/svg%3E");
  }
  .ic-link-03 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10 13a5 5 0 007.54.54l3-3a5 5 0 00-7.07-7.07l-1.72 1.71M14 11a5 5 0 00-7.54-.54l-3 3a5 5 0 007.07 7.07l1.71-1.71'/%3E%3C/svg%3E");
  }
  .ic-minus {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 12h14'/%3E%3C/svg%3E");
  }
  .ic-save-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 3v3.4c0 .56 0 .84.109 1.054a1 1 0 00.437.437C7.76 8 8.04 8 8.6 8h6.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C17 7.24 17 6.96 17 6.4V4m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C16.24 13 15.96 13 15.4 13H8.6c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C7 13.76 7 14.04 7 14.6V21M21 9.325V16.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C3 18.72 3 17.88 3 16.2V7.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C5.28 3 6.12 3 7.8 3h6.875c.489 0 .733 0 .963.055.204.05.4.13.579.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z'/%3E%3C/svg%3E");
  }
  .ic-check {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  }
  .ic-check-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7.5 12l3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-download-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 15v1.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C3 18.72 3 17.88 3 16.2V15m14-5l-5 5m0 0l-5-5m5 5V3'/%3E%3C/svg%3E");
  }
  .ic-flag-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1v19'/%3E%3C/svg%3E");
  }
  .ic-message-question-circle {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10.5 9.002a2.248 2.248 0 014.369.75C14.869 11.25 12.62 12 12.62 12m.028 3h.01m-.16 5a8.5 8.5 0 10-8.056-5.783c.108.32.162.481.172.604a.899.899 0 01-.028.326c-.03.12-.098.245-.232.494l-1.636 3.027c-.233.432-.35.648-.324.815a.5.5 0 00.234.35c.144.087.388.062.876.011l5.121-.529c.155-.016.233-.024.303-.021.07.002.12.009.187.024.069.016.155.05.329.116A8.478 8.478 0 0012.5 20z'/%3E%3C/svg%3E");
  }
  .ic-bar-chart-12 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 12H4.6c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C3 12.76 3 13.04 3 13.6v5.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C3.76 21 4.04 21 4.6 21H9m0 0h6m-6 0V8.6c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C9.76 7 10.04 7 10.6 7h2.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C15 7.76 15 8.04 15 8.6V21m0 0h4.4c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C21 20.24 21 19.96 21 19.4V4.6c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C20.24 3 19.96 3 19.4 3h-2.8c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C15 3.76 15 4.04 15 4.6V8'/%3E%3C/svg%3E");
  }
  .ic-upload-01 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 15v1.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C3 18.72 3 17.88 3 16.2V15m14-7l-5-5m0 0L7 8m5-5v12'/%3E%3C/svg%3E");
  }
  .ic-chevron-down {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  }
  .ic-clock {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 6v6l4 2m6-2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z'/%3E%3C/svg%3E");
  }
  .ic-expand-06 {
    --ic:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M16 8l5-5m0 0h-5m5 0v5M8 8L3 3m0 0v5m0-5h5m0 13l-5 5m0 0h5m-5 0v-5m13 0l5 5m0 0v-5m0 5h-5'/%3E%3C/svg%3E");
  }
  .ic-albums {
    --ic: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%23212121' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='1' y='1' width='12' height='15' fill='none' stroke='%23212121' stroke-width='2'%3E%3C/rect%3E %3Cpolyline points='17.001 6.619 22.598 8.132 18.684 22.613 7.099 19.481' fill='none' stroke='%23212121' stroke-width='2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
  }
}
.swal-overlay.swal-overlay--show-modal {
  display: block;
  @apply bg-back/80;
}

.swal-modal {
  @apply bg-mute;
}

.swal-title {
  @apply font-cabinet text-3xl font-bold text-lead;
}

.swal-text {
  @apply text-base text-subs;
}

.swal-content {
  text-align: left;
  @apply bg-mute;
}

.swal-footer {
  display: flex;
  padding-top: 0;
}

.swal-button-container {
  @apply flex-1 gap-4 flex-wrap;
}

.swal-button {
  box-shadow: none !important;
  border: 0 !important;
  @apply text-base;
}

.swal-button--cancel {
  @apply btn btn-seco btn-lg;
}

.swal-button--confirm {
  @apply btn btn-main btn-lg;
}

.swal-button--danger {
  @apply btn btn-fire btn-lg;
}

.is-hidden {
  @apply hidden;
}

/* home: upload */
#tab-urls .uploads .field {
  @apply mb-2;
}

/********** edit-form **********/
/* swal  */
/* custom swal */
.swal-success-container .swal-footer .swal-button-container {
  @apply text-center;
}
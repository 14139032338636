@charset "utf-8";

.swal-overlay.swal-overlay--show-modal {
  display: block;

  @apply bg-back/80;
}

.swal-modal {
  @apply bg-mute;
}

.swal-title {
  @apply font-cabinet text-3xl font-bold text-lead;
}

.swal-text {
  @apply text-base text-subs;
}

.swal-content {
  text-align: left;

  @apply bg-mute;
}

.swal-footer {
  display: flex;
  padding-top: 0;
}

.swal-button-container {
  @apply flex-1 gap-4 flex-wrap;
}

.swal-button {
  box-shadow: none !important;
  border: 0 !important;

  @apply text-base;
}

.swal-button--cancel {
  @apply btn btn-seco btn-lg;
}

.swal-button--confirm {
  @apply btn btn-main btn-lg;
}

.swal-button--danger {
  @apply btn btn-fire btn-lg;
}
@charset "utf-8";
@import "_main";
@import "_swal";

.is-hidden {
  @apply hidden;
}

/* home: upload */
#tab-urls {
  .uploads {
    .field {
      @apply mb-2;
    }
  }
}

/********** edit-form **********/

/* swal  */
// .swal-edit-form {
//   @apply swal-general;

//   .swal-title {
//     font-size: 1rem;
//     color: inherit;
//   }

//   /* swal.footer */
//   .swal-footer {
//     display: flex;
//     padding-top: 0;

//     .swal-button-container {
//       @apply flex-1 gap-4 flex-wrap;
//     }
//   }
// }

/* custom swal */

.swal-success-container {
  .swal-footer {
    .swal-button-container {
      @apply text-center
    }
  }

}